import "./noData.css";

const NoData = ({ heading }) => {
  return (
    <tr>
      <td colSpan={6}>
        <div className="no-data">
          {/* <div className="no-data-content"> */}
          <i className="la la-exclamation-triangle" />
          <h6>{heading ? heading : "No Data Available"}</h6>
          {/* </div> */}
        </div>
      </td>
    </tr>
  );
};
export default NoData;
