/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NoData from "./noData/noData.jsx";
import TableLoader from "./loader/loader.jsx"
import Breadcrumb from "../common/breadcrumb";
import { debounce } from "lodash";
import { FaSearch, FaCaretUp, FaCaretDown } from "react-icons/fa";
import { getDate, capitalizeFirstLetter } from "./dateUtlis.js";
import { getTransactionHistory } from "./api";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
  FormGroup,
} from "reactstrap";
// import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { toast } from "react-toastify";
import user from "../../assets/images/user.png";

const Transaction = () => {

  console.log("was I called here fella");
  const initialState = {
    gameType: "all",
    currentPage: 1,
    searchTerm: "",
    limit:10
  };
  const [managedState, setmanagedState] = useState(initialState);
  const [mainLoading, setMainLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);


  const handleFilter=(val)=>{
    setmanagedState((prev)=>({...prev,gameType:val}))
  }

  const handlePageChange = (data) => {
    setMainLoading(true);
    const selectedPage = data.selected + 1;
    setmanagedState((prev) => {
      return { ...prev, currentPage: selectedPage };
    });
  };

  const handleSearchTermChange = (event) => {
    setmanagedState((prev) => {
      return { ...prev, currentPage: 1, searchTerm: event.target.value };
    });
  };

  const handleLimitCheck=(limitValue)=>{
    setmanagedState((prev)=>{
      return  { ...prev, limit:parseInt(limitValue) };
    })
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getTransactionHistoryData = async () => {
      setMainLoading(true);
      const response = await getTransactionHistory(managedState, source.token);
      setMainLoading(false);
      if (response.code === 200) {
        setData(response.transactions);
        setTotalPages(response.totalPages);
      } else {
        toast.error(response.message, { toastId: "error-kyc-feature" });
      }
    };

    const debouncedFetchData = debounce(getTransactionHistoryData, 600);

    debouncedFetchData();

    return () => {
      source.cancel("Request canceled");
    };
  }, [managedState]);


  console.log("these are the data",data);

  return (
    <>
      <div className="userlist-page">
        <Breadcrumb title="Transaction" parent="Transaction" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="transactionSelect">
                  <div className="selectContainer">
                    <select
                      aria-label="Default select example"
                      className="form-control"
                      onChange={(e) => handleFilter(e.target.value)}
                    >
                      <option value="all">All Transaction</option>
                      <option value="limbo">Limbo</option>
                      <option value="crash">Crash</option>
                      <option value="plinko">Plinko</option>
                      <option value="slot">Slot</option>
                    </select>
                  </div>
                  <FormGroup className="searchFromgroup">
                    <Input
                      type="text"
                      placeholder="Search by name"
                      value={managedState.searchTerm}
                      onChange={handleSearchTermChange}
                      className="searchFromInput"
                    />
                    <FaSearch className="searchlens" />
                  </FormGroup>
                </CardHeader>
                <CardBody className="user-datatable">

                   <div className="admin-table">
                          <div className="row">
                            <div className="col-md-12">
                              <Table
                                hover
                                responsive
                                class={`table table-hover ${
                                  data.length < 0 ? "no-data-table" : ""
                                }${mainLoading ? "loading" : ""}`}
                              >
                                <TableHeader />
                                <tbody>
                                  {!mainLoading ? (
                                    data.length > 0 ? (
                                      data.map((dataElement) => {
                                        return (
                                          <>
                                            <TableRow dataElement={dataElement}/>
                                          </>
                                        );
                                      })
                                    ) : (
                                      
                                        <NoData />
                                        
                                    )
                                  ) : (
                                    
                                        <TableLoader />
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                 {totalPages>1 && (
                 <div className="adminPagination">
                    <div className="transactionSelect ">
                      <div className="selectContainer">
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          onChange={(e) => handleLimitCheck(e.target.value)}
                          defaultValue={10}
                        >
                          <option disabled>Select Page</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    

                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={"next >"
                    }
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    pageCount={totalPages}
                    previousLabel={ "< previous"
                    }
                    forcePage={parseInt(managedState.currentPage) - 1}
                    renderOnZeroPageCount={null}
                  />
                   </div>)}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};


const TableHeader=()=>{
  return (
    <>
      <thead>
        <tr>
           <th>Avatar</th>
           <th>Name</th>
           <th>Date</th>
           <th>Game</th>
           <th>Wagered</th>
           {/* <th>Multiplier</th> */}
           <th>Profit</th>
        </tr>
      </thead>
    </>
  );
}


const TableRow = ({ dataElement }) => {
  console.log('dataElement?.profit',dataElement?.profit);
  return (
    <>
      <tr>
        <td className="latest-user-image"><img src={dataElement?.user[0]?.avatar || user} alt="user profile"/></td>
        <td>{dataElement?.user[0]?.name}</td>
        <td>{getDate(dataElement.createdAt)}</td>
        <td>{capitalizeFirstLetter(dataElement?.gameType)}</td>
        <td>{dataElement?.amount}</td>
        {/* <td>{dataElement?.multiplier || '-'}</td> */}
        {dataElement?.profit && <td className={`${
                                dataElement?.profit?.toString()?.startsWith("-")
                                  ? "blockActive"
                                  : "userStatusActive"
                              } `}
                            >
                             <div className="user-amountTransaction">
                                {dataElement?.profit?.toString()?.startsWith("-") ? (
                                  <>
                                    {dataElement?.profit?.toFixed(2) * -1}
                                    <FaCaretDown />
                                  </>
                                ) : (
                                  <>
                                    {dataElement?.profit?.toFixed(2)}
                                    <FaCaretUp />
                                  </>
                                )}
                              </div>
                            </td>}
                           {(!dataElement?.profit && (dataElement?.profit !==0))&&<td>-</td>}                    
      </tr>
    </>
  );
};

export default Transaction;
