import { FaDiceD20 } from "react-icons/fa";
import {
  Users,
  // Box,
  DollarSign,
  // Tag,
  // Clipboard,
  // Camera,
  // AlignLeft,
  // UserPlus,
  // Chrome,
  // BarChart,Settings,Archive,
  LogIn,
} from "react-feather";

export const MENUITEMS = [
  // {
  //   path: "/dashboard",
  //   title: "Dashboard",
  //   icon: Home,
  //   type: "link",
  //   badgeType: "primary",
  //   active: false,
  // },
  // {
  //     title: 'User', icon: Users, type: 'sub', active: false, children: [
  //         { path: '/User/user-list', title: 'all Users', type: 'link' },
  //     ]
  // },
  {
    path: "/User/user-list",
    title: "User",
    icon: Users,
    type: "link",
    active: false,
  },
  {
    title: "Transaction",
    path: "/transaction",
    icon: DollarSign,
    type: "link",
    active: false,
  },
  {
    title: "Games",
    icon: FaDiceD20,
    type: "sub",
    active: false,
    children: [
      { path: "/games/limbo", title: "Limbo", type: "link" },
      { path: "/games/crash", title: "Crash", type: "link" },
      { path: "/games/slot", title: "Slot", type: "link" },
      { path: "/games/plinko", title: "Plinko", type: "link" },
    ],
  },
  // {
  //   title: "Manage Prizes",
  //   path: "/prizes",
  //   icon: FaGift,
  //   type: "link",
  //   active: false,
  // },
  // {
  //   title: "KYC",
  //   path: "/kyc",
  //   icon: FaAddressCard,
  //   type: "link",
  //   active: false,
  // },
  {
    title: "Logout",
    path: "/auth/login",
    icon: LogIn,
    type: "link",
    active: false,
  },
];
