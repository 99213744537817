import { adminInstance } from "../../config/axios";
import axios from 'axios';



export const getTransactionHistory=async(managedState,cancelToken)=>{
   try{
        const payload={
            limit:managedState.limit,
            page:managedState.currentPage,
            gameType:managedState.gameType,
            searchTerm:managedState.searchTerm
        }
        const response = await adminInstance().post("/getTransactionHistory",  
          payload,
          { cancelToken })

        return response?.data;
    }catch(error){
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            return error?.response?.data;
          } 
    }
}
     
export const getBetsHistory=async(managedState,cancelToken,gameType)=>{
   try{
        const payload={
            limit:managedState.limit,
            page:managedState.currentPage,
            gameType:gameType,
            searchTerm:managedState.searchTerm
        }
        const response = await adminInstance().post("/getBetHistory",  
          payload,
          { cancelToken })

        return response?.data;
    }catch(error){
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            return error?.response?.data;
          } 
    }
}


