import { Spinner } from "react-bootstrap";
import "./loader.css";

const TableLoader = () => {
  return (
    <tr>
      <td colSpan={6}>
        <div className="table-loader">
          <Spinner animation="border" />
        </div>
      </td>
    </tr>
  );
};

export default TableLoader;
